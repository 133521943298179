// Fetch queryParams on startup and put them in sessionStorage so we can access them after the SPA router takes over
const startupParams = window.location.href.split('?')[1];
if (startupParams) {
    const paramList = startupParams.split('&');
    paramList.forEach(param => {
        const [key, value] = param.split('=');
        if (key && value) {
            try {
                sessionStorage.setItem(key, value);
            } catch (e) { console.error(e) }
        }
    });
}

// Ligth/dark mode favicon
const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

if (mediaQuery.matches) {
    updateFavicon(mediaQuery.matches);
}

// Suscribe to changes
mediaQuery.addEventListener('change', (e) => updateFavicon(e.matches));

function updateFavicon(darkmode) {
    const favicon = document.getElementById('favicon');
    if (darkmode) {
        favicon.setAttribute('href', 'config/dist/theme/assets/favicon-darkmode.ico')
    } else {
        favicon.setAttribute('href', 'config/dist/theme/assets/favicon.ico')
    }
}